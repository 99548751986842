import { useState } from 'react';
import { Container, Form, FormGroup, Card, CardHeader, CardBody, Label, Button, Input, Col, DropdownMenu, DropdownToggle, DropdownItem, UncontrolledDropdown, InputGroup, InputGroupText, Table, Row } from 'reactstrap';
import * as jose from 'jose';
import CloseCircleIcon from 'mdi-react/CloseCircleIcon';
import './App.css';

const COPY_TO_CLIPBOARD_TEXT = 'Copy to clipboard';

interface AdditionalClaim {
	key: string,
	name: string,
	value: string
}

const createAndAddSecondsToDate = (numSeconds: number) => {
	return new Date((new Date()).getTime() + numSeconds);
}

const createAndAddMinutesToDate = (numMinutes: number) => {
	return new Date((new Date()).getTime() + numMinutes);
}

const createAndAddYearsToDate = (numYears: number) => {
	const result = new Date();
	result.setFullYear(result.getFullYear() + numYears);
	return result;
}

const App = () => {
	const [issuer, setIssuer] = useState('example.com');
	const [issuedAt, setIssuedAt] = useState(new Date().toISOString());
	const [expiration, setExpiration] = useState(createAndAddYearsToDate(1).toISOString());
	const [audience, setAudience] = useState('example.com');
	const [subject, setSubject] = useState('test@example.com');
	const [additionalClaims, setAdditionalClaims] = useState<AdditionalClaim[]>([]);
	const [alg, setAlg] = useState<'HS256' | 'HS384' | 'HS512'>('HS256');
	const [signKey, setSignKey] = useState('YourSecretKey');
	const [signedToken, setSignedToken] = useState('');
	const [copyButtonText, setCopyButtonText] = useState(COPY_TO_CLIPBOARD_TEXT);

	const generateSignedToken = async () => {
		const secret = new TextEncoder().encode(signKey)

		const result = await new jose.SignJWT(additionalClaims.reduce((res, claim) => {
			if (claim.value === '' || claim.name === '') {
				return res;
			}

			res[claim.name] = claim.value;

			return res;
		}, {} as Record<string, string>))
			.setProtectedHeader({
				alg
			})
			.setIssuer(issuer)
			.setIssuedAt(Math.floor(new Date(issuedAt).getTime() / 1000))
			.setExpirationTime(Math.floor(new Date(expiration).getTime() / 1000))
			.setAudience(audience)
			.sign(secret);

		setSignedToken(result);
	}

	const addAdditionalClaimsRow = () => {
		const updatedArray = Array.from(additionalClaims);
		updatedArray.push({
			key: `${Math.random()}`,
			name: '',
			value: ''
		})

		setAdditionalClaims(updatedArray);
	}

	const removeAdditionalClaimsRow = (suppliedClaim: AdditionalClaim) => {
		const filteredArray = additionalClaims.filter(claim => claim.key !== suppliedClaim.key && claim.name !== suppliedClaim.name);

		setAdditionalClaims(filteredArray)
	}

	const setAdditionalClaimsName = (claimsList: AdditionalClaim[], name: string, index: number) => {
		const updatedArray = Array.from(claimsList);
		updatedArray[index].name = name;

		setAdditionalClaims(updatedArray)
	};

	const setAdditionalClaimsValue = (claimsList: AdditionalClaim[], value: string, index: number) => {
		const updatedArray = Array.from(claimsList);
		updatedArray[index].value = value;

		setAdditionalClaims(updatedArray)
	};

	const copyTokenToClipboard = async () => {
		let newText = '';
		try {
			await navigator.clipboard.writeText(signedToken);
			newText = 'Copied';
		} catch (err) {
			newText = 'Unable to copy';
		} finally {
			setCopyButtonText(newText);
			setTimeout(() => {
				setCopyButtonText(COPY_TO_CLIPBOARD_TEXT);
			}, 4000)
		}
	}

	return (
		<div className="App">
			<Container>
				<Form>
					<Card className='primary-card'>
						<CardHeader className='primary-card-header'>
							Standard Claims
						</CardHeader>
						<CardBody>
							<FormGroup row>
								<Label htmlFor="issuer" sm={2}>Issuer</Label>
								<Col sm={4}>
									<Input type="text" id="issuer" value={issuer} onChange={(e) => setIssuer(e.target.value)} />
								</Col>
								<p className="col-sm-5 text-success">Identifier (or, name) of the server or system issuing the token. Typically a DNS name, but doesn&apos;t have to be.</p>
							</FormGroup>
							<FormGroup row>
								<Label htmlFor="issuedAt" sm={2}>Issued At</Label>
								<Col sm={4}>
									<Input type="text" id="issuedAt" value={issuedAt} onChange={(e) => setIssuedAt(e.target.value)} />
								</Col>
								<Col className="col-sm-5">
									<p className="text-success">Date/time when the token was issued. (defaults to now)
										&nbsp;
										<Button type="button" color="success" onClick={() => setIssuedAt(new Date().toISOString())}>now</Button>
									</p>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label htmlFor="subject" sm={2}>Expiration</Label>
								<Col sm={4}>
									<Input type="text" id="expiration" value={expiration} onChange={(e) => setExpiration(e.target.value)} />
								</Col>
								<Col sm={5}>
									<p className="text-success">Date/time at which point the token is no longer valid. (defaults to one year from now)
										&nbsp;
										<Button type="button" color="success" onClick={() => setExpiration(createAndAddSecondsToDate(0).toISOString())}>now</Button>
										{' '}
										<Button type="button" color="success" onClick={() => setExpiration(createAndAddMinutesToDate(20).toISOString())}>in 20 minutes</Button>
										{' '}
										<Button type="button" color="success" onClick={() => setExpiration(createAndAddYearsToDate(1).toISOString())}>in 1 year</Button>
									</p>
								</Col>
							</FormGroup>
							<FormGroup row>
								<Label htmlFor="audience" sm={2}>Audience</Label>
								<Col sm={4}>
									<Input type="text" id="audience" value={audience} onChange={(e) => setAudience(e.target.value)} />
								</Col>
								<p className="col-sm-5 text-success">Intended recipient of this token; can be any string, as long as the other end uses the same string when validating the token. Typically a DNS name.</p>
							</FormGroup>
							<FormGroup row>
								<Label htmlFor="subject" sm={2}>Subject</Label>
								<Col sm={4}>
									<Input type="text" id="subject" value={subject} onChange={(e) => setSubject(e.target.value)} />
								</Col>
								<p className="col-sm-5 text-success">Identifier (or, name) of the user this token represents.</p>
							</FormGroup>
						</CardBody>
					</Card>
					<Card className='primary-card'>
						<CardHeader className='primary-card-header'>
							Additional Claims
						</CardHeader>
						<CardBody>
							<FormGroup row>
								<Col sm={{ size: 5, offset: 1 }}>
									<Table>
										<thead>
											<tr>
												<th>Claim Type</th>
												<th>Value</th>
												<th />
											</tr>
										</thead>
										<tbody>
											{additionalClaims.map((claim, index) => {
												return (
													<tr key={`${claim.key}`}>
														<td style={{ width: '150px' }}>
															<Input type="text" value={claim.name} onChange={(e) => setAdditionalClaimsName(additionalClaims, e.target.value, index)} />
														</td>
														<td>
															<Input type="text" value={claim.value} onChange={(e) => setAdditionalClaimsValue(additionalClaims, e.target.value, index)} />
														</td>
														<td>
															<Button onClick={() => removeAdditionalClaimsRow(claim)}>
																<CloseCircleIcon />
															</Button>
														</td>
													</tr>
												)
											})}
										</tbody>
									</Table>
								</Col>
								<Col sm={5}>
									<p className="text-success">Use this section to define zero or more custom claims for your token.
										The claim type can be anything, and so can the value.</p>
									<p>&nbsp;</p>
									<p>
										<Button color='success' onClick={() => setAdditionalClaims([])}>clear all</Button>
										{' '}
										<Button color='success' onClick={addAdditionalClaimsRow}>add one</Button>
									</p>
								</Col>
							</FormGroup>
						</CardBody>
					</Card>
					<Card className='primary-card'>
						<CardHeader className='primary-card-header'>
							Plaintext Token
						</CardHeader>
						<CardBody>
							<FormGroup row>
								<Col sm={{ size: 8, offset: 1 }}>
									<pre className='code'>
										{
											JSON.stringify({
												"iss": issuer,
												"iat": Math.floor(new Date(issuedAt).getTime() / 1000),
												"exp": Math.floor(new Date(expiration).getTime() / 1000),
												"aud": audience,
												"sub": subject,
												...additionalClaims.reduce((res, claim) => {
													if (claim.value === '' || claim.name === '') {
														return res;
													}

													res[claim.name] = claim.value;

													return res;
												}, {} as Record<string, string>)
											}, null, 2)
										}
									</pre>
								</Col>
								<Col sm={2}>
									<p className=" text-success">This section displays the claims that will be signed and base64-encoded into a complete JSON Web Token.</p>
									<p>&nbsp;</p>
									<p><em>
									</em></p><ul data-bind="foreach: warnings"></ul><em>
									</em><p></p>
								</Col>
							</FormGroup>
						</CardBody>
					</Card>
					<Card className='primary-card'>
						<CardHeader className='primary-card-header'>
							Signed JSON Web Token
						</CardHeader>
						<CardBody>
							<FormGroup row inline>
								<Label for="key" sm={2} style={{ textAlign: 'right' }}>Key</Label>
								<Col sm={6}>
									<InputGroup>
										<Input type="text" invalid={signKey.length === 0} id="key" style={{ width: '90%' }} value={signKey} onChange={(e) => setSignKey(e.target.value)} />
										<InputGroupText>{signKey.length}</InputGroupText>
									</InputGroup>
								</Col>
								<Col sm={1}>
									<UncontrolledDropdown>
										<DropdownToggle caret>{alg}</DropdownToggle>
										<DropdownMenu>
											<DropdownItem onClick={() => setAlg('HS256')}>HS256</DropdownItem>
											<DropdownItem onClick={() => setAlg('HS384')}>HS384</DropdownItem>
											<DropdownItem onClick={() => setAlg('HS512')}>HS512</DropdownItem>
										</DropdownMenu>
									</UncontrolledDropdown>
								</Col>
								<Col sm={3}>
									<Button color='success' onClick={generateSignedToken}>Create Signed JWT</Button>
								</Col>
								<Col md={12}>
									&nbsp;
								</Col>
								<Col sm={{ size: 8, offset: 2 }}>
									<Row>
										<pre className='code' style={{ height: '8pc', whiteSpace: 'pre-wrap', wordWrap: 'break-word' }}>
											{signedToken}
										</pre>
										<Button onClick={copyTokenToClipboard}>{copyButtonText}</Button>
									</Row>
								</Col>
							</FormGroup>
						</CardBody>
					</Card>
				</Form>
			</Container>
		</div>
	);
}

export default App;
